<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">电费账单列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
                <el-button icon="el-icon-download" class="s-btn-exp" @click="exportfile">模版下载</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="exportBill">批量添加</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editElectric">添加电费</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input label="电费单号" v-model="pageParam.params.name" />
                <le-select-remote-search label="电费项目" v-model="pageParam.params.id" :options="optionsElectric" placeholder="请选择电费项目(可搜索)" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择站点(可搜索)" />
                <le-select-remote-search label="电表号" v-model="pageParam.params.ammeterId" :options="optionsAmmeter" placeholder="请选择电表(可搜索)" />
                <le-select-local-search label="收款方" v-model="pageParam.params.status" :options="chargePayeeTypeDic" />
                <le-select-remote-search label="收款物业" v-model="pageParam.params.propertyId" :options="optionsProperty" placeholder="请选择物业(可搜索)" />
                <le-select-local-search label="付款方式" v-model="pageParam.params.projectType" :options="optionsProjectType" />
                <le-select-local-search label="发票规则" v-model="pageParam.params.projectType" :options="optionsProjectType" />
                <le-select-local-search label="状态" v-model="pageParam.params.projectType" :options="optionsProjectType" />
                <le-date-range label="创建时间" :minDate.sync="pageParam.params.startTime" :maxDate.sync="pageParam.params.endTime" valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-select-remote-search label="标签" v-model="pageParam.params.propertyId" :options="optionsProperty" collapse multiple placeholder="请选择物业(可搜索)" />
            </le-search-form>
            <le-pagview ref="propertyListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.propertyList" :pageSizeDefault='10'>
                <el-table ref="propertyList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="电费单号" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="电费项目" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.accountNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="站点" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.bankName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="电表" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.bankBranchName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="收款方" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="收款物业" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="付款方式" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }} {{ row.alertDate ? row.alertDate.split('-')[2]:'-' }}日</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="发票规则" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }} {{ row.dueDate ? row.dueDate.split('-')[2]:'-' }}日</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="当期用电" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="单价" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="创建时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="标签" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="200" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleSendCommand(scope.row)">详情</el-button>
                            <el-button type="text" @click="handleUploadInvoice(scope.row)">上传发票</el-button>
                            <el-button type="text" @click="handleToPay(scope.row)">处理付款</el-button>
                            <el-button class="a-c-error" type="text" @click="handleToReject(scope.row)">驳回</el-button>
                            <el-button class="a-c-error" type="text" @click="handleRevoke(scope.row)">撤回</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <le-upload-file-img 
            v-show="false" 
            :uploadUrl="$Config.apiUrl.importUserCoupon" 
            :acceptArr="['.xls','.xlsx']" 
            notnull 
            ref="pic" 
            label="" 
            :limit="999"
            @handleSuccess="importExcSuccess"
            v-model="redXLS"></le-upload-file-img>
        <electric-bill-invoice ref='invoice'></electric-bill-invoice>
        <electric-bill-pay ref="payBill"></electric-bill-pay>
        <electric-bill-reject ref="rejectPay"></electric-bill-reject>
    </div>
</template>

<script>
import ElectricBillInvoice from './child/electric-bill-invoice.vue'
import util from '../../../src/utils/util'
import ElectricBillPay from './child/electric-bill-pay.vue'
import electricBillReject from './child/electric-bill-reject.vue'
    export default {
        components: {
            ElectricBillInvoice,
            ElectricBillPay,
            electricBillReject
        },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.propertyCompanyPage,
                    method: "post",
                    params: {
                        name: '',
                        accountNumber: '',
                        startTime: '',
                        endTime: ''
                    },
                    freshCtrl: 1,
                },
                chargePayeeTypeDic: [],
                optionsProjectType: [],
                optionsElectric: {
                    url: this.$Config.apiUrl.electricFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsStation: {
                    url: this.$Config.apiUrl.getStationList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "stationId",
                    searchKey: "name",
                    pageSize: 100
                },//站点数据
                optionsAmmeter: {
                    url: this.$Config.apiUrl.ammeterFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsProperty: {
                    url: this.$Config.apiUrl.propertyCompanyfilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
            }
        },
        created () {
            // this.$getDic('timeCycle','select').then(res=>{ this.timeCycleDic = res; })
            // this.$getDic('billType','select').then(res=>{ this.billTypeDic = res; })
            this.$getDic('chargePayeeType','select').then(res=>{ this.chargePayeeTypeDic = res; })
            this.$getDic('chargeFeeType','select').then(res=>{ this.optionsProjectType = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = [1]
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    accountNumber: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['propertyListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 添加电费账单
            editElectric () {
                this.$router.push({
                    path: '/electric/electric-charge-add',
                })
            },
            exportBill () {
                this.$refs['pic'].$refs["upload"].$refs["upload-inner"].handleClick();
            },
            importExcSuccess (data) {
                this.$message.success('导入成功')
                // this.exportData = data
                // if(data && data.length){
                //     this.$refs['exportResult'].dialogVisible = true
                // }
                this.pageParam.freshCtrl++; // 重新请求表格数据
            },
            handleUploadInvoice () {
                this.$refs['invoice'].dialogVisible = true
            },
            handleToPay () {
                this.$refs['payBill'].dialogVisible = true
            },
            handleToReject () {
                this.$refs['rejectPay'].dialogVisible = true
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
// /deep/ .s-search-label{
//     width: 100px
// }
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>