<template>
    <div>
        <el-dialog
            title="处理付款"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <el-form-item label="付款回单" prop="refundAmount">
                    <le-upload-file-img-ly ref="pic" v-model="form.pictureList" :limit='10'></le-upload-file-img-ly>
                </el-form-item>
                <el-form-item label="付款时间" prop="refundAmount">
                    <el-date-picker
                        v-model="value1"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                id: '',
                form: {
      
                },
                rules:{

                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                       
                    }
                })
            },
        }
    }
</script>