<template>
    <div>
        <el-dialog
            title="驳回"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <el-form-item label="驳回原因" prop="refundAmount">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        placeholder="请输入内容"
                        v-model="form.refundMsg">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                id: '',
                form: {
      
                },
                rules:{

                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                       
                    }
                })
            },
        }
    }
</script>